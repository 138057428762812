@font-face {
    font-family: Hogfish;
    src: url('./fonts/Hogfish.otf');
    font-weight: 400;
    font-weight: normal;
}

@font-face {
    font-family: Chihaya Jun;
    src: url('./fonts/Chihaya_Jun.ttf');
    font-weight: 400;
    font-weight: normal;
}

body {
    background: #1d1d1d;
    margin: 0;
}

.game__container {
    margin: 0 auto;
    max-width: 1000px;
}